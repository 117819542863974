import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  public hostingUrl = "http://siragga-api.kabbdg.com/api/v1/";
  // public hostingUrl = 'http://localhost:81/saharga-api/api/v1/';
  //local echo
  // public hostingUrl = "http://192.168.10.10:8000/api/v1/";
  public api_token = "";
  public authenticationState = new BehaviorSubject(false);

  public httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
    }),
  };

  constructor(public http: HttpClient, private router: Router) {}

  async getToken() {
    if (localStorage.getItem("auth_app_token")) {
      this.api_token = JSON.parse(localStorage.getItem("auth_app_token")).value;
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: "application/json",
          Authorization: "bearer " + this.api_token,
        }),
      };

      let base64Url = this.api_token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      let payload = JSON.parse(window.atob(base64));
      payload.token = this.api_token;

      localStorage.setItem("USER_INFO", JSON.stringify(payload));
    } else {
      // this.router.navigate(["auth/login"]);
    }
  }

  async extractData(res: Response) {
    let body = res.json();
    return body;
  }

  async handleError(error: Response | any) {
    return Promise.reject(error);
  }

  async getDataWithPagination(url, data): Promise<any> {
    // await this.getToken();
    return this.http
      .post(url, data, this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

   // get years item
   async getTahun(): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-tahun", this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  /**
   * fungsi untuk memanggil satuan combobox
   *
   * @returns {Promise<any>}
   * @memberof ItemService
   */
  async getTypeSsh(): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-typessh", this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  async getCategory(keyword): Promise<any> {
    await this.getToken();
    return this.http
      .post(this.hostingUrl + "front-category", { keyword: keyword }, this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  async getGallery(): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-gallery", this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  async getStores(): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-store", this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  // #region item
  async getItems(url, data): Promise<any> {
    await this.getToken();
    return this.http
      .post(url, data, this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  async getCategoryName(id): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-category/" + id, this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  async getCategoryNonSHB(group_id): Promise<any> {
    await this.getToken();
    return this.http
      .get(this.hostingUrl + "front-categorynonshb/" + group_id, this.httpOptions)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }
}
