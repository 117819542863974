import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saran',
  templateUrl: './saran.component.html',
  styleUrls: ['./saran.component.scss']
})
export class SaranComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
