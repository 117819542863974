<header>
  <div class="header-area ">
      <div id="sticky-header" class="main-header-area">
          <div class="container-fluid ">
              <div class="header_bottom_border">
                  <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-2">
                          <div class="logo">
                              <a href="index.html">
                                  <img src="assets/img/01__saharga6.png" width="100%" alt="">
                              </a>
                          </div>
                      </div>
                      <div class="col-xl-6 col-lg-7">
                          <div class="main-menu  d-none d-lg-block">
                              <nav>
                                  <ul id="navigation">
                                    <li><a href="#">Profile</a></li>
                                    <li><a href="#">Informasi</a></li>
                                    <li><a href="#">Layanan</a></li>
                                      <li><a >Kritik & Saran</a></li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 d-none d-lg-block">
                          <div class="Appointment">
                              <div class="d-none d-lg-block">
                                  <a class="boxed-btn3" href="https://saharga.id/panel/#/auth/login">Login</a>
                              </div>
                          </div>
                      </div>
                      <div class="col-12">
                          <div class="mobile_menu d-block d-lg-none"></div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>
</header>

<div class="bradcam_area bradcam_bg_1">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3 style="color: #ffffff"> Kritik & Saran</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-section section_padding" style="background-color: white;">
  <div class="container">
    <div class="d-none d-sm-block mb-5 pb-4">
      <div class="row">
        <div class="col-12">
          <h2 class="contact-title">Lampirkan Kritik dan saran</h2>
        </div>
        <div class="col-lg-8">
          <form class="form-contact contact_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder="Enter Message"></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name"></div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Enter email address"></div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <input class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject"></div>
              </div>
            </div>
            <div class="form-group mt-3">
              <button type="submit" class="button button-contactForm btn_4 boxed-btn">Send Message</button>
            </div>
          </form>
        </div>
        <div class="col-lg-4">
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-home"></i></span>
            <div class="media-body">
              <h3>Bagian Kesra, SETDA Kab. Bandung(</h3>
              <p>
                Kesejahteraan Masyarakat
              </p>
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
            <div class="media-body">
              <h3>(+62) 0814 000 xxx</h3>
              <p>
                Mon to Fri 9am to 6pm
              </p>
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-email"></i></span>
            <div class="media-body">
              <h3>support@kesra.id</h3>
              <p>
                Kirim pertanyaan Anda kapan saja!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
