import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { Lightbox } from 'ngx-lightbox';
import 'hammerjs';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import Swal from "sweetalert2";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  loading = false;
  loadingCateogry = false;
  readonly position = { lat: -7.025253, lng: 107.51976 };

  data = {
    from: null,
    to: null,
    total: null,
    first_page_url: null,
    last_page_url: null,
    prev_page_url: null,
    next_page_url: null,
    data: [],
  };

  pagination = {
    page: 1,
    row: "25",
    keyword: null,
    sortby: "created_at",
    sorttype: "desc",
  };

  category = [];
  galleries = [
    {
      thumb: 'assets/images/A1.jpg',
      src: 'assets/images/A1.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_2.jpg',
      src: 'assets/images/_2.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_3.jpg',
      src: 'assets/images/_3.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_4.jpg',
      src: 'assets/images/_4.jpg',
      caption: 'test',
    },
  ];
  stores = [];
  store = null;
  markers = [];
  keyword = '';
  _albums = [
    {
      thumb: 'assets/images/A1.jpg',
      src: 'assets/images/A1.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_2.jpg',
      src: 'assets/images/_2.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_3.jpg',
      src: 'assets/images/_3.jpg',
      caption: 'test',
    },
    {
      thumb: 'assets/images/_4.jpg',
      src: 'assets/images/_4.jpg',
      caption: 'test',
    },
  ];

  url = "";
  selectedItem = "25";

  async ngOnInit() {
    await this.getData(this.url);
  }

  constructor(
    private service: ApiServiceService,
    private router: Router,
    private _lightbox: Lightbox
  ) {
    this.url = this.service.hostingUrl + "sarana-paging?";
    // this.getCategory();
    // this.getGallery();
    // this.getStores();
  }

  getCategory() {
    this.loadingCateogry = true;
    this.service.getCategory(this.keyword).then((result) => {
      this.category = result.data;
      this.loadingCateogry = false;
    });
  }

  getGallery() {
    this.service.getGallery().then((result) => {
      this.galleries = result.data;
      this.galleries.forEach((element) => {
        // this._albums.push({
        //   // src: element.file,
        //   // caption: element.description,
        //   // thumb: element.file
        // });
      });
    });
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  getStores() {
    this.service.getStores().then((result) => {
      this.stores = result.data;
      console.log(this.stores);
      this.stores.forEach((element) => {
        this.markers.push({
          position: {
            lat: Number(element.lat),
            lng: Number(element.long),
          },
          label: {
            color: 'red',
            text: 'Marker label ' + (this.markers.length + 1),
          },
          title: element.name,
        });
      });
    });
  }

  openInfo(title) {
    this.store = null;

    const finds = this.stores.filter((item) => {
      return item.name == title;
    });

    this.store = finds[0];
  }

  /**
   *go to lampiran 1 or 2
   *
   * @param {*} number
   * @memberof AppComponent
   */
  goToLampiran() {
    let person = prompt('Masukan password :');
    if (person == 'saharga101220') {
      this.router.navigate(['lampiran']);
    }
  }

  substring(name) {
    if (name) {
      if (name.toString().length > 30) {
        return name.substring(0, 30) + '...';
      } else {
        return name;
      }
    }
  }

  /**
   * go to items page
   *
   * @param {*} category_id
   * @memberof HomeComponent
   */
  goToItems(category_id) {
    this.router.navigate(['item/' + category_id]);
  }

  goToSaran() {
    this.router.navigate(['saran']);
  }

  /**
   * get data by category from api
   *
   * @param {*} url
   * @memberof CategoryComponent
   */
  getData(url) {
    this.loading = true;
    this.service
      .getDataWithPagination(url, this.pagination)
      .then((result) => {
        //data from API json
        this.data = result.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log('error', error);
        this.loading = false;
        if (error.error == 'Unauthorized.') {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Session login anda sudah habis silahkan login kembali',
          });
          this.loading = false;
          this.router.navigate(['auth/login']);
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong!' + error.msg,
          });
          this.loading = false;
        }
      });
  }

   /**
   * fungsi pencarian ketika klik button search
   *
   * @memberof UsulanComponent
   */
    search() {
      if (this.pagination.keyword.length > 1) {
        this.getData(this.url);
      } else {
        this.pagination.keyword = null;
        this.getData(this.url);
      }
    }
  
    /**
     * fungsi pencarian ketika pencet enter
     *
     * @param {*} event
     * @memberof CategoryComponent
     */
    searchEnter(event) {
      if (event.key === "Enter") {
        this.search();
      }
    }
  
    getRow() {
      this.getData(this.url);
    }
}
