import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoogleMapsModule, GoogleMap } from '@angular/google-maps';
import { SaranComponent } from './saran/saran.component';
import { LampiranComponent } from './lampiran/lampiran.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ItemComponent } from './item/item.component';

import { Angular2ImageGalleryModule } from "angular2-image-gallery";
import { LightboxModule } from 'ngx-lightbox';
// import { NgxGalleryModule } from 'ngx-gallery';

@NgModule({
  declarations: [
    AppComponent,
    SaranComponent,
    LampiranComponent,
    LoginComponent,
    HomeComponent,
    ItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    GoogleMapsModule,
    Angular2ImageGalleryModule,
    LightboxModule
    // NgxGalleryModule
  ],
  providers: [GoogleMap],
  bootstrap: [AppComponent]
})
export class AppModule { }
