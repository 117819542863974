<!-- header-start -->
<header>
  <div class="header-area">
    <div id="sticky-header" class="main-header-area">
      <div class="container-fluid">
        <div class="header_bottom_border">
          <div class="row align-items-center"> 
            <div class="col-xl-3 col-lg-2">
              <div class="logo">
                <a href="index.html">
                  <img src="assets/img/01__saharga14.png" width="100%" alt="" />
                </a>
              </div>
            </div>
         
            
            <div class="col-xl-6 col-lg-7">
              <div class="main-menu d-none d-lg-block">
                <nav>
                  <ul id="navigation">
                    <li><a href="#">Visi Misi<i class="ti-angle-down"></i></a>
                      <ul class="submenu">
                          <li><a href="assets/visimisi.pdf">File..</a></li>

                      </ul>
                      <li><a href="assets/sejarah.pdf">Sejarah<i class="ti-angle-down"></i></a>
                        <ul class="submenu">
                            <li><a href="https://id.wikipedia.org/wiki/Kabupaten_Bandung">Wikipedia</a></li>
                        </ul>

                        <li><a href="assets/Struktur.pdf">Struktur<i class="ti-angle-down"></i></a>
                          <ul class="submenu">
                              <li><a href="assets/Struktur.pdf">File</a></li>
                     
                          </ul>
                           
                    <li><a (click)="goToSaran()">Kritik & Saran </a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 d-none d-lg-block">
              <div class="Appointment">
                <div class="d-none d-lg-block">
                  <a class="boxed-btn3" href="http://admin.siragga.kabbdg.com"
                    >Login</a
                  >
                </div>
              </div>
            </div>
            
        
            <div class="col-12">
              <div class="mobile_menu d-block d-lg-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header-end -->

<!-- slider_area_start -->
<div class="slider_area">
  <div class="single_slider d-flex align-items-center slider_bg_1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="slider_text">
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- slider_area_end -->

<!-- catagory_area -->
<div class="catagory_area container">
  <div class="container">
    <div class="section_title mb-40">
      <h3 style="text-align: center">
        <strong>
          DATA SARANA/ LEMBAGA PERIBADATAN DAN PENDIDIKAN KEAGAMAAN DI KABUPATEN
          BANDUNG
        </strong>
      </h3>
    </div>
  </div>

  <div class="text-center" *ngIf="loadingCateogry">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <h5>Tunggu Sebentar...</h5>
  </div>

  <h2 style="text-align: center">
    Daftar Tempat Peribadatan di Kabupaten Bandung
  </h2>
  <br />
  <div class="row">
    <div class="col-lg-6"></div>
    <!-- /.col-lg-6 -->
    <div class="col-lg-6">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Search for..."
          (keydown)="searchEnter($event)"
          [(ngModel)]="pagination.keyword"
        />
        <span class="input-group-btn">
          <button  class="btn btn-success" (click)="search()">Cari!</button>
        </span>
      </div>
      <!-- /input-group -->
    </div>
    <!-- /.col-lg-6 -->
  </div>
  <!-- /.row -->

  <br />

  <div class="table-responsive">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th scope="col" rowspan="2">#</th>
          <th scope="col" style="cursor: pointer" rowspan="2">
            Sarana Peribadatan
          </th>
          <th scope="col" style="cursor: pointer" rowspan="2">
            Kategori Masjid
          </th>
          <th
            scope="col"
            style="cursor: pointer; text-align: center"
            colspan="3"
          >
            Alamat Lengkap
          </th>
          <th scope="col" style="cursor: pointer" rowspan="2">Nama Pimpinan</th>
          <th scope="col" style="cursor: pointer" rowspan="2">No Telepon</th>
          <th scope="col" style="cursor: pointer" rowspan="2">Email</th>
          <th scope="col" style="cursor: pointer" rowspan="2">Tahun Berdiri</th>
          <th
            scope="col"
            style="cursor: pointer; text-align: center"
            colspan="2"
          >
            Legalitas
          </th>
          <th
            scope="col"
            style="cursor: pointer; text-align: center"
            colspan="9"
          >
            Profil
          </th>

          <th scope="col" style="cursor: pointer" rowspan="2">
            Aplikasi Dengan Ormas
          </th>
          <th scope="col" style="cursor: pointer" colspan="4">
            Pernah Menerima Bantuan
          </th>

          <th
            scope="col"
            style="cursor: pointer; text-align: center"
            colspan="2"
          >
            Berdasarkan Simas
          </th>
        </tr>
        <tr>
          <th>Kecamatan</th>
          <th>Desa/Kelurahan</th>
          <th>Alamat</th>
          <th scope="col" style="cursor: pointer">No Id Mesjid</th>
          <th scope="col" style="cursor: pointer">No Tgl SK Pengurusan</th>
          <th scope="col" style="cursor: pointer">Status Tanah</th>
          <th scope="col" style="cursor: pointer">Luas Tanah</th>
          <th scope="col" style="cursor: pointer">Luas Bangunan</th>
          <th scope="col" style="cursor: pointer">SDM Pengurus</th>
          <th scope="col" style="cursor: pointer">SDM Jamaah</th>
          <th scope="col" style="cursor: pointer">SDM Imam</th>
          <th scope="col" style="cursor: pointer">SDM Khatib</th>
          <th scope="col" style="cursor: pointer">SDM Muazin</th>
          <th scope="col" style="cursor: pointer">SDM Remaja</th>
          <th scope="col" style="cursor: pointer">APBD</th>
          <th scope="col" style="cursor: pointer">APBN</th>
          <th scope="col" style="cursor: pointer">CSR</th>
          <th scope="col" style="cursor: pointer">Lainnya</th>
          <th scope="col" style="cursor: pointer">Lokasi Peta</th>
          <th scope="col" style="cursor: pointer">Profile Masjid</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dt of data.data; let i = index">
          <th scope="row">{{ data.from + i }}.</th>
          <td>{{ dt?.nama }}</td>
          <td>{{ dt?.kategori }}</td>
          <td>{{ dt?.kecamatan }}</td>
          <td>{{ dt?.desa }}</td>
          <td>{{ dt?.alamat }}</td>
          <td>{{ dt?.pimpinan }}</td>
          <td>{{ dt?.telepon }}</td>
          <td>{{ dt?.email }}</td>
          <td>{{ dt?.tahun_berdiri }}</td>
          <td>{{ dt?.mesjid_id }}</td>
          <td>{{ dt?.tanggal_sk }}</td>
          <td>{{ dt?.status_tanah }}</td>
          <td>{{ dt?.luas_tanah }}</td>
          <td>{{ dt?.luas_bangunan }}</td>
          <td>{{ dt?.jml_sdm_pengurus }}</td>
          <td>{{ dt?.jml_sdm_jamaah }}</td>
          <td>{{ dt?.jml_sdm_imam }}</td>
          <td>{{ dt?.jml_sdm_khatib }}</td>
          <td>{{ dt?.jml_sdm_muazin }}</td>
          <td>{{ dt?.jml_sdm_remaja }}</td>
          <td>{{ dt?.ormas }}</td>
          <td>{{ dt?.is_apbd == 1 ? "Ya" : "Tidak" }}</td>
          <td>{{ dt?.is_apbn == 1 ? "Ya" : "Tidak" }}</td>
          <td>{{ dt?.is_csr == 1 ? "Ya" : "Tidak" }}</td>
          <td>{{ dt?.is_lainnya == 1 ? "Ya" : "Tidak" }}</td>
          <td>
            <button class="btn btn-success" size="small">
              <a target="blank" [href]="dt?.lokasi_peta" style="color: white"
                >Lihat Lokasi Peta</a
              >
            </button>
          </td>
          <td>
            <button size="small"  class="btn btn-success">
              <a
                target="blank"
                [href]="dt?.url_profile_mesjid"
                style="color: white"
                >Lihat Profile Masjid</a
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="data.data.length == 0">
      <h5 class="center margin">Data tidak ada</h5>
    </ng-container>
  </div>

  <br />

  <div class="halaman">
    <span class="page">Baris Perhalaman :</span>

    <select
      class="page"
      placeholder="Select Showcase"
      (selectedChange)="getRow()"
      [(ngModel)]="pagination.row"
    >
      <option value="25">25</option>
      <option value="250">250</option>
      <option value="500">500</option>
    </select>

    <span class="page"
      >{{ data.from }} - {{ data.to }} dari {{ data.total }}
    </span>

    <button
      class="btn btn-success"
      ghost
      [disabled]="data.first_page_url == null"
      (click)="getData(data.first_page_url)"
    > <<
      <!-- <nb-icon
        icon="arrowhead-left-outline"
        [options]="{ animation: { type: 'zoom' } }"
      ></nb-icon> -->
    </button>

    <button
      class="btn btn-success"
      ghost
      status="primary"
      [disabled]="data.prev_page_url == null"
      (click)="getData(data.prev_page_url)"
    >
      <!-- <nb-icon
        icon="arrow-ios-back-outline"
        [options]="{ animation: { type: 'zoom' } }"
      ></nb-icon> -->
      <
    </button>

    <button
      class="btn btn-success"
      ghost
      status="primary"
      [disabled]="data.next_page_url == null"
      (click)="getData(data.next_page_url)"
    >
      <!-- <nb-icon
        icon="arrow-ios-forward-outline"
        [options]="{ animation: { type: 'zoom' } }"
      ></nb-icon> -->
      >
    </button>

    <button
      class="btn btn-success"
      ghost
      status="primary"
      [disabled]="data.last_page_url == null"
      (click)="getData(data.last_page_url)"
    >
      <!-- <nb-icon
        icon="arrowhead-right-outline"
        [options]="{ animation: { type: 'zoom' } }"
      ></nb-icon> -->
    >>
    </button>
  </div>

  <br />

  <select class="form-control" name="combo1" id="combo1">
    <option value="">
      <strong
        >Pilih Katagori Tempat Ibadah Agama Protestan, Katolik, Hindu, Buddha,
        Khonghucu</strong
      >
    </option>
    <option value="Nama Provinsi 4">Gereja</option>
    <option value="Nama Provinsi 1">Pura</option>
    <option value="Nama Provinsi 2">Vihara</option>
    <option value="Nama Provinsi 3">Klenteng/ Litang</option>
  </select>
  <br />

  <table class="table">
    <tr style="text-align: center">
      <td><strong>BERDASARKAN AGAMA </strong></td>
      <td><strong>NAMA TEMPAT IBADAH</strong></td>
      <td><strong>ALAMAT</strong></td>
      <td><strong>TAHUN BERDIRI</strong></td>
      <td><strong>KEPEMILIKAN</strong></td>
      <td><strong>SK PENGURUS</strong></td>
      <td><strong>IZIN OPRASIONAL</strong></td>
    </tr>
    <tr>
      <td>Protestan</td>
      <td>Gereja</td>
      <td>Kp. Cipanjang RT03 / RW02 Kecamatan. Soreang Kab. Bandung</td>
      <td>1990</td>
      <td>Yayasan</td>
      <td>Felicya</td>
      <td>01.1.15.05.10.000001</td>
    </tr>
    <tr>
      <td>Katolik</td>
      <td>Gereja</td>
      <td>Kp. Cipanjang RT03 / RW02 Kecamatan. Soreang Kab. Bandung</td>
      <td>1990</td>
      <td>Lain-Lain</td>
      <td>Imanuel</td>
      <td>01.1.15.05.10.000002</td>
    </tr>
    <tr>
      <td>Hindu</td>
      <td>Pura</td>
      <td>Kp. Pajagalan RT01 / RW04 Kecamatan Soreang Kab. Bandung</td>
      <td>1985</td>
      <td>Yayasan</td>
      <td>Prihadi</td>
      <td>01.1.15.05.10.000003</td>
    </tr>

    <tr>
      <td>Buddha</td>
      <td>Vihara/Wihara</td>
      <td>Kp. Pajagalan RT02 / RW04 Kecamatan Soreang Kab. Bandung</td>
      <td>1984</td>
      <td>Lain-Lain</td>
      <td>Surya</td>
      <td>01.1.15.05.10.000003</td>
    </tr>

    <tr>
      <td>Khonghucu</td>
      <td>Kelenteng/Litang</td>
      <td>Kp. Pajagalan RT02 / RW04 Kecamatan Soreang Kab. Bandung</td>
      <td>1984</td>
      <td>Yayasan</td>
      <td>Fang Yin</td>
      <td>01.1.15.05.10.000003</td>
    </tr>
  </table>

  <br /><br />

  <div class="row">
    <div class="col-12">
      <button
        style="width: 100%"
        (click)="goToLampiran()"
        class="btn btn-success"
      >
        Penyusunan rencana teknis
      </button>
    </div>
  </div>
</div>
<!--/ catagory_area -->

<div>
  <div class="container">
    <div class="section_title mb-40">
      <h2 style="text-align: center">
        Peta Mesjid & tempat Peribadatan Lainnya
      </h2>
    </div>
  </div>

  <div class="row container-fluid">
    <iframe
      width="100%"
      height="450"
      style="border: 0"
      loading="lazy"
      allowfullscreen
      src="https://www.google.com/maps/embed/v1/search?q=mesjid%20soreang&key=AIzaSyBnCGsgZjH1r8Yi1vSh5__f_XifvCGnbBQ"
    ></iframe>

    <!-- <div [ngClass]="{'col-8': store, 'col-12':!store }">
      <google-map [center]="position" [zoom]="10" width="100%" height="36.5625rem">
        <map-marker #markerElem
          *ngFor="let marker of markers"
          [position]="marker.position"
          [title]="marker.title"
          [options]="marker.options"
          (mapClick)="openInfo(marker.title)"
        >
        </map-marker>
      </google-map>
      <map-info-window>Hello Google Maps</map-info-window>
    </div>
    <div class="col-4" *ngIf="store">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" [src]="store.photo_1" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" [src]="store.photo_2" alt="Second slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" [src]="store.photo_3" alt="Third slide">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <table class="table">
        <tr>
          <td>Nama Toko : </td><td>{{ store?.name }}</td>
        </tr>
        <tr>
          <td>Alamat : </td><td>{{ store?.address }}</td>
        </tr>
        <tr>
          <td>Kategori Toko : </td><td>{{ store?.categori.name }}</td>
        </tr>
        <tr>
          <td>Tahun Survey : </td><td>{{ store?.tahun }}</td>
        </tr>
      </table>
    </div> -->
  </div>
</div>

<section class="mbr-gallery mbr-slider-carousel" id="gallery2-0">
  <br />
  <div class="container">
    <div class="section_title mb-40">
      <h2 style="text-align: center">Dokumentasi Kegiatan (Photo Gallery)</h2>
    </div>
  </div>
  <div class="mbr-gallery-row" *ngIf="galleries.length > 0">
    <div class="mbr-gallery-layout-default">
      <div
        class="mbr-gallery-item mbr-gallery-item--p0"
        *ngFor="let image of _albums; let i = index"
      >
        <img [src]="image.thumb" (click)="open(i)" /><span
          class="icon-focus"
        ></span>
      </div>
      <!-- 
        <div class="mbr-gallery-item mbr-gallery-item--p0">
          <img src="assets/images/1.jpg" (click)="open(i)" /><span class="icon-focus"></span>
        </div>
        <div class="mbr-gallery-item mbr-gallery-item--p0">
          <img src="assets/images/2.jpg" (click)="open(i)" /><span class="icon-focus"></span>
        </div>
        <div class="mbr-gallery-item mbr-gallery-item--p0">
          <img src="assets/images/3.jpg" (click)="open(i)" /><span class="icon-focus"></span>
        </div>
        <div class="mbr-gallery-item mbr-gallery-item--p0">
          <img src="assets/images/4.jpg" (click)="open(i)" /><span class="icon-focus"></span>
        </div> -->
      <div class="clearfix"></div>
    </div>
  </div>
</section>

<!-- footer start -->
<footer class="footer">
  <div class="footer_top">
    <div
      class="copy-right_text wow fadeInUp"
      data-wow-duration="1.4s"
      data-wow-delay=".3s"
    >
      <div class="container">
        <div class="footer_border"></div>
        <div class="row">
          <div class="col-xl-12">
            <p class="copy_right text-center">
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              All rights reserved &copy;
              <script>
                document.write(new Date().getFullYear());
              </script>
              SETDA Kabupaten Bandung <br />
              Bagian Kesra (Kesejahteraan Rakyat) Subbagian Bina Mental
              Spiritual
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
