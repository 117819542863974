<!-- header-start -->
<header>
    <div class="header-area ">
        <div id="sticky-header" class="main-header-area">
            <div class="container-fluid ">
                <div class="header_bottom_border">
                    <div class="row align-items-center">
                        <div class="col-xl-3 col-lg-2">
                            <div class="logo">
                                <a href="index.html">
                                    <img src="assets/img/01__saharga.png" width="100%" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7">
                            <div class="main-menu  d-none d-lg-block">
                                <nav>
                                    <ul id="navigation">
                                        <li><a href="#">List SSH</a></li>
                                        <li><a href="#">GIS SaHarga</a></li>
                                        <li><a href="#">Galery SaHarga</a></li>
                                        <li><a href="#">masukan</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mobile_menu d-block d-lg-none"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</header>
<!-- header-end -->

<!-- catagory_area -->
<div class="catagory_area">
    <div class="container">
        <div class="section_title mb-40">
            <h3>Standar Biaya Umum (SBU)</h3>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="pagination.group_id" (change)="getLampiran()">
                <option value="5">SBU</option>
                <option value="2">HSPK</option>
                <option value="2">ASB</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <div class="from-group">
              <input type="text" [(ngModel)]="pagination.keyword" placeholder="Cari Items" class="form-control">
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <select *ngIf="types"  [(ngModel)]="pagination.type" class="form-control">
                <option  [value]="type.type" *ngFor="let type of types">{{ type?.type }}</option>
              </select>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <select [(ngModel)]="pagination.year" class="form-control">
                <option  [value]="tahun.tahun" *ngFor="let tahun of years">{{ tahun?.tahun }}</option>
              </select>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <select [(ngModel)]="pagination.categori_id" class="form-control">
                <option  [value]="c.id" *ngFor="let c of category">{{ c?.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
                <button (click)="search()" class="btn btn-success">Cari</button>
            </div>
          </div>
        </div>

				<div>
					<table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Kode Komponen</th>
                <th scope="col">Uraian Komponen</th>
                <th scope="col">Spesifikasi</th>
                <th scope="col">Satuan</th>
                <th scope="col">Harga</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dt of data.data; let i = index;">
                <th scope="row">{{ data.from + i }}.</th>
                <td>{{ dt?.kode_barang }}</td>
                <td>{{ dt?.uraian }}</td>
                <td>{{ dt?.description }}</td>
                <td>{{ dt?.satuan_name }}</td>
                <td>{{ dt?.price | number }}</td>
              </tr>
            </tbody>
          </table>
          <ng-container *ngIf="data.data.length == 0">
            <h5 class="center margin">Data tidak ada</h5>
          </ng-container>
        </div>

        <div class="center margin">
          <nav aria-label="Page navigation example" style="margin: 10px;">
            <ul class="pagination"><span  style="margin: 10px;" class="page">Baris Perhalaman :</span>
              <select  style="margin: 10px;" class="form-control" style="width: 80px;" placeholder="Select Showcase" (change)="getRow()" [(ngModel)]="pagination.row">
                <option value="25">25</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
              <span  style="margin: 10px;" class="page">{{ data.from }} - {{ data.to }} dari {{ data.total }} </span>
              <li class="page-item"><button class="page-link" [disabled]="data.first_page_url == null" (click)="getData(data.first_page_url)"><<</button></li>
              <li class="page-item"><button class="page-link" [disabled]="data.prev_page_url == null" (click)="getData(data.prev_page_url)"><</button></li>
              <li class="page-item"><button class="page-link" [disabled]="data.next_page_url == null" (click)="getData(data.next_page_url)">></button></li>
              <li class="page-item"><button class="page-link" [disabled]="data.last_page_url == null" (click)="getData(data.last_page_url)">>></button></li>
            </ul>
          </nav>
        </div>

        <br />

        <button (click)="home()" class="btn btn-success">Kembali Ke Halaman Utama</button>
    </div>
</div>

