import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  loading = false;

  url = '';
  years = [];
  types = [];
  category = [];
  galleries = [];
  stores = [];
  markers = [];
  categoryName;

  pagination = {
    row: '25',
    sortby: 'created_at',
    sorttype: 'desc',
    categori_id: this.route.snapshot.paramMap.get("categori_id"),
    keyword: "",
    year: null,
    type: null,
    group_id: 3
  }

  data = {
    from: 0,
    to: 0,
    total: 0,
    first_page_url: null,
    last_page_url: null,
    prev_page_url: null,
    next_page_url: null,
    data: []
  };

  constructor(private service: ApiServiceService,
    private router: Router, private route: ActivatedRoute) {
      this.url = this.service.hostingUrl + 'front-item?';
      this.getTahun();
      this.getTypeSSH();
      this.getCategoryName();

     setTimeout(() => {
       this.getData(this.url);
     }, 700);
    }

  ngOnInit(): void {
  }

   /**
   *get tahun by items for combobox
   *
   * @memberof ItemComponent
   */
  getTahun() {
     this.service.getTahun().then(
      result => {
        console.log(result.data);
        this.years = result.data;
        this.pagination.year = this.years[0].tahun;
      }
     )
  }

  getTypeSSH() {
    this.service.getTypeSsh().then(
      result => {
        this.types = result.data;
        console.log(this.types);
        this.pagination.type = this.types[0].type;
      }
    )
  }



  /**
   * get data by cabang from api
   *
   * @param {*} url
   * @memberof CabangComponent
   */
  getData(url) {
    this.loading = true;
    this.service.getItems(url, this.pagination).then(
      result => {
        console.log('kadie', result);
        this.data = result.data;

        this.loading = false;
      }
    ).catch(
      error => {
        this.loading = false;

        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Something went wrong!' + error.msg,
        })
      }
    )
  }

   /**
   * fungsi pencarian ketika klik button search
   *
   * @memberof PbfComponent
   */
  search() {
      this.getData(this.url)
  }

  /**
   * get data by row combobox
   *
   * @memberof ItemComponent
   */
  getRow() {
    this.getData(this.url)
  }

  /**
   *kembali ke halaman home
   *
   * @memberof ItemComponent
   */
  home() {
    this.router.navigate(['home']);
  }

  getCategoryName() {
    this.service.getCategoryName(this.route.snapshot.paramMap.get("categori_id")).then(
      result => {
        this.categoryName = result.data;
        console.log('hehe', this.categoryName);
      }
    )
  }
}
