import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LampiranComponent } from "./lampiran/lampiran.component";
import { SaranComponent } from "./saran/saran.component";
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ItemComponent } from './item/item.component';

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "item/:categori_id",
    component: ItemComponent,
  },
  {
    path: "saran",
    component: SaranComponent,
  },
  {
    path: "lampiran",
    component: LampiranComponent,
    //canActivate: [AuthGuardService]
  },
  {
    path: "login",
    component: LoginComponent,
  },
  { path: "", redirectTo: "home", pathMatch: "full" },
  //{ path: "**", redirectTo: "home" },
];

const config: ExtraOptions = {
  useHash: true,
  // onSameUrlNavigation: "reload",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
